<template>
  <div>
    <Breadcrumbs :main="$tc('models.frequentQuestion.entity', 1)" :title="breadcrumbTitle"/>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="mt-4">
            <b-card-body>
              <app-form ref="form" :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import FormsMixin from 'mixins/FormsMixin';

export default {
  name: 'FrequentQuestionForm',
  data() {
    return {
      modelName: 'FrequentQuestion',
      fields: {
        question: {
          type: 'text',
          key: 'question',
          value: '',
          label: this.$t('models.frequentQuestion.attributes.question'),
          required: true,
          classes: 'col-12 py-0',
          valid: false
        },
        answer: {
          type: 'text-area',
          key: 'answer',
          value: '',
          label: this.$t('models.frequentQuestion.attributes.answer'),
          required: true,
          classes: 'col-12 py-0',
          valid: false
        },
        tagList: {
          type: 'searchableSelect',
          allowMultiple: false,
          taggable: true,
          key: 'tagList',
          value: [],
          searchData: [],
          autocompleteAction: this.suggestTags,
          label: this.$t('models.frequentQuestion.attributes.category'),
          required: true,
          valid: false,
          classes: 'col-12 py-0'
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      record: 'frequentQuestion',
      currentUser: 'currentUser',
      tagsList: 'tagsList'
    })
  },
  methods: {
    ...mapActions({
      getRecord: 'getFrequentQuestion',
      createRecord: 'createFrequentQuestion',
      updateRecord: 'updateFrequentQuestion',
      searchTags: 'searchTags'
    }),
    suggestTags(val) {
      this.searchTags({...this.options, filterBy: {key: val, model: 'FrequentQuestion'}}).then((response) => {
        this.fields.tagList.searchData = this.tagsList.items;
      });
    }
  },
  mixins: [FormsMixin]
};
</script>
